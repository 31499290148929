﻿/// <reference path = "../../main.scss" />
// File Name: _widget-cards.scss
// Description: Styles for the Grid Editor
// ------------------------------------------------------------

.text-card-cta {
    margin-top: 30px;
    margin-bottom: 30px;

    .card-inner {
        padding-bottom: 80px;
        position: relative;
    }

    .card-block {
        padding: 0;
        padding-right: 15px;
        margin: 0;
        /*margin: 15px 0;
        padding-right: 15px;
        padding-left: 15px;*/
        a.btn-card {
            padding: 8px 30px;
            margin-top: 0px;
            border-radius: 0;
            text-transform: uppercase;
            font-family: $headings-font-family;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.3;
            min-width: 220px;
            position: absolute;
            bottom: 0;
            left: 0;
            white-space: initial;
        }
    }

    .card-title {
        color: $odu-aqua-blue-mid;
        font-weight: bold;
        font-size: 23px;
        text-transform: uppercase;
    }

    .subtitle {
        color: $black;
        font-weight: bold;
        font-size: 18px;
        text-transform: none;
        margin-bottom: 5px;
    }

    .card-text {
        font-size: 16px;

        p {
            line-height: 1.4;
        }
    }
}
.card-section + .card-section {
    .text-card-cta .card-block {
        border-right: 0;
    }

    .text-card-cta .card-inner {
        border-right: 1px solid $odu-dark-gray;
    }

    .column:last-child .text-card-cta .card-inner {
        border-right: none;
    }

    @include media-breakpoint-down(md) {
        .text-card-cta .card-block {
            height: auto !important;
        }

        .text-card-cta .card-inner {
            border-right: none;
            padding-right: 0;
            padding-bottom: 50px;
        }

        .text-card-cta {
            margin-top: 15px;
            margin-bottom: 15px;
        }

        .text-card-cta:last-child {
            margin-bottom: 30px;
        }

        .text-card-cta:first-child {
            margin-top: 30px;
        }
    }
}
﻿/// <reference path = "../../main.scss" />
// File Name: _widget-cards.scss
// Description: Styles for the Grid Editor
// ------------------------------------------------------------

.card {
    border-radius: 0;
    border: none;

    &.light-gray-short-card {
        background-color: $odu-light-gray;
        margin-top: 30px;
        margin-bottom: 30px;

        .card-block {
            text-align: center;
            padding: 30px;
        }

        @include media-breakpoint-down(md) {
            margin-bottom: 15px;
        }
    }

    .card-inner {

        .img-holder {
            position: relative;
            overflow: hidden;
            transform: translate3d(0, 0, 0);

            .card-img {
                border-radius: 0;
                width: 100%;
                transition: all 0.3s;
            }
        }
    }

    .card-img {
    }

    h4.card-title {
        color: $odu-slate-blue;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 20px;
        margin-bottom: 5px;
    }

    .text-xs-center {
        text-align: center;
    }

    .text-xs-right {
        text-align: right;
    }

    .card-text {
    }

    .no-render {
        display: none;
    }

    &.link-enabled:hover {
        .text {
            background-color: rgba(0,0,0, 0.0);
        }

        .card-inner {

            .card-img {
                filter: saturate(140%) brightness(120%);
                transform: scale(1.05);
                margin-left: 0%;
                margin-top: 0%;
                backface-visibility: hidden;
            }
        }
    }
}
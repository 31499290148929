﻿/// <reference path = "../../main.scss" />
// File Name: _variables.scss
// Description: Project variables
// ------------------------------------------------------------

$font-family-sans-serif:    'Source Sans Pro', sans-serif;
$headings-font-family:      "Montserrat", sans-serif; //'Raleway', 'Open Sans', Arial, sans-serif !default;
$font-family-alternate:     'Open Sans', Arial, sans-serif !default;
//$font-family-serif:      Georgia, "Times New Roman", Times, serif !default;
//$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
//$font-family-base:       $font-family-sans-serif !default;

$sample-site-blue: #1b32db;
$sample-site-green: #1bdb76;

$odu-black: #0d0d0d;
$odu-slate-blue-dark: #020d21;
$odu-slate-blue: #0a2457;

$odu-slate-blue-dull: #173966;

$odu-slate-blue-hover: #022e85;
$odu-slate-blue-active: #084dac;
/*$odu-slate-blue: #043965;*/
$odu-aqua-blue-dark: #084dac;
$odu-aqua-blue: #01b3fb;
$odu-aqua-blue-mid: #1099cd;

$odu-aqua-blue-ada: #009ddc;
$odu-aqua-blue-mid-ada: #0d7ca7;
$odu-gray-blue-disclaimer-ada: #979ea5;

$odu-dark-gray: #8c9497;
$odu-darker-gray: #313541;
$odu-dark-green: #537d1b;
$odu-light-green: #6da329; 
$odu-light-green-active: #61a10d;

$odu-light-green-nav-ada: #4d7319;
$odu-dark-green-ada: #406116;
$odu-light-green-ada: #56811D;

$odu-gray-blue: #959ca2;
$odu-light-gray-blue: #bec5cb;
$odu-lighter-gray-blue: #ebf1f6;
$odu-header-gray: #f5f8fb;
$odu-light-gray: #e9e9e9;
$odu-highlight-blue: #084dab;
$odu-highlight-blue-opacity: rgba(8, 77, 171, 0.90);
/*$odu-burnt-orange: #c42a0e;
$odu-burnt-orange-lighter: #ee401e;*/
$odu-testimonial-gray: #c8cdd1;
$odu-teal-blue-link: #0bb3fc;
$odu-text-link: #084cad;
$odu-fundraising-member-gray: #bdd5f1;
/*
    
*/
//approved site theme colors so we can use this in sass loops with our approved color module
$approved-colors-map: ( blue: $sample-site-blue, green: $sample-site-green );
﻿/// <reference path = "../../main.scss" />
// File Name: _testimonials.scss
// Description: Styles for the Grid Editor
// ------------------------------------------------------------

/*col 12 size*/
.column-12.quote {
    .quote {
        background-color: $odu-testimonial-gray;
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: -32px;

        .card-inner {
            @include make-container();
            @include make-container-max-widths();


            .text {
                @include make-row();

                .inner-text {
                    /*font-family: $font-family-alternate;*/
                    @include make-col-ready();

                    @include media-breakpoint-up(lg) {
                        @include make-col(12);
                    }

                    .quote-text {
                        font-size: 1.5em;
                        position: relative;

                        &::before {
                            /*font-size: 2.0em;
                            font-family: Fontawesome;
                            content: "\f10d";*/
                            content: " ";
                            height: 59px;
                            width: 42px;
                            margin-bottom: 10px;
                            display: block;
                            background-image: url("/dist/img/large-quote.png");
                            background-repeat: no-repeat;
                        }

                        &::after {
                            content: " ";
                            height: 59px;
                            width: 42px;
                            margin-bottom: 10px;
                            display: block;
                            background-image: url("/dist/img/large-quote-end.png");
                            background-repeat: no-repeat;
                            position: absolute;
                            bottom: -55px;
                            right: 0px;
                            z-index: 0;
                        }
                    }

                    .author-org-holder {
                        margin-top: 45px;
                        display: inline-block;
                        float: right;

                        .author, .organization {
                            font-size: 15px;
                            text-align: right;
                        }

                        .author {
                            text-align: left;
                            margin-left: -8px;
                        }

                        .organization {
                            font-style: italic;
                        }
                    }

                    padding: 30px 15px 60px 15px;
                }
            }
        }
        /* Inline style */
        &.inline {
            background-color: transparent;

            .card-inner {
                .text {

                    .inner-text {
                        padding: 30px 40px 90px 30px;

                        .quote-text {
                            font-size: 38px;
                            font-weight: bold;
                            color: $black;
                            font-style: italic;
                            position: relative;
                            text-align: right;
                            font-family: $headings-font-family;

                            @include media-breakpoint-down(sm) {
                                font-size: 28px;
                            }

                            &::before {
                                content: " ";
                                height: 58px;
                                width: 62px;
                                margin-bottom: 10px;
                                display: block;
                                background-image: url("/dist/img/large-quote-blue.png");
                                background-repeat: no-repeat;
                                position: absolute;
                                top: -9px;
                                left: 0;
                                z-index: 0;
                            }

                            &::after {
                                content: " ";
                                height: 58px;
                                width: 62px;
                                margin-bottom: 10px;
                                display: block;
                                background-image: url("/dist/img/large-quote-blue-end.png");
                                background-repeat: no-repeat;
                                position: absolute;
                                bottom: -30px;
                                right: -45px;
                                z-index: 0;
                            }

                            p {
                                line-height: 1.1;
                                position: relative;
                                z-index: 1;
                                margin-top: 25px;
                                margin-left: 25px;
                            }
                        }

                        .author-org-holder {
                            max-width: 50%;
                            margin-top: 15px;

                            .author, .organization {
                                text-align: right;
                            }

                            .author {
                            }

                            .organization {
                            }
                        }

                        @include media-breakpoint-down(md) {
                            & {
                                padding: 30px 30px 90px 30px;
                            }

                            .author-org-holder {
                                margin-top: 25px;
                            }

                            .quote-text {

                                &::after {
                                    right: -25px;
                                    bottom: -28px;
                                }

                                p {
                                    margin-right: 0px;
                                }

                                & {
                                    padding: 0px 0px 30px 35px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.column-12.quote {
    .quote.inline {
        max-width: 785px;
        margin-left: auto;
        margin-right: auto;
    }
}



﻿.board-of-directors-grid {
    .board-of-directors-section {
        margin-top: 10px;
        margin-bottom: 10px;

        h2 {
            color: $odu-slate-blue;
            font-size: 24px;
            text-align: left;
            margin-bottom: 1.5rem;
            text-transform: none;
        }

        .board-member-row {
            @include make-row();
            justify-content: center;

            .board-member-box {
                @include make-col-ready();
                @include make-col(3);

                @include media-breakpoint-down(md) {
                    @include make-col(6);
                }

                @include media-breakpoint-down(xs) {
                    @include make-col(12);
                }

                margin-bottom: 30px;

                img {
                    width: 100%;
                }



                .text-content {
                    text-align: center;
                    padding: 10px;
                    padding-bottom: 25px;
                    background-color: $odu-light-gray;

                    h3 {
                        font-size: 20px;
                        font-weight: bold;
                        margin-bottom: 0.25rem;
                        color: $black;
                    }

                    .position {
                        font-size: 16px;
                        font-weight: bold;
                        display: block;
                    }

                    .organization {
                        font-size: 14px;
                        line-height: 1.3;
                        font-weight: normal;
                        font-style: italic;
                        display: block;
                    }
                }

                &.no-image .text-content {
                    padding-top: 60px;
                    padding-bottom: 60px;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
    }
}

﻿/// <reference path = "../../main.scss" />
// File Name: _image-text-cta.scss
// Description: Styles for the Grid Editor
// ------------------------------------------------------------

.image-text-cta {
    @include make-row();
    margin-top: 25px;

    a {
        border: 4px solid $white;

        &.btn {
            white-space: initial;
        }
    }

    &.align-right {
        > .image {
            order: 2;
        }
    }

    &.slate-blue {
        background-color: $odu-slate-blue-dark;
        color: $white;

        .text {
            background-color: $odu-slate-blue;
        }

        .link-area {
            a {
            }

            a:hover {
                background-color: $white;
                border-color: $white;
                color: $odu-slate-blue-dark;
            }
        }
    }
    /*other colors*/
    &.aqua-blue {
        background-color: $odu-aqua-blue-dark;
        color: $white;

        .text {
            background-color: $odu-aqua-blue;
        }

        .link-area {
            a {
            }

            a:hover {
                background-color: $white;
                border-color: $white;
                color: $odu-aqua-blue-dark;
            }
        }
    }

    &.green {
        background-color: $odu-dark-green;
        color: $white;

        .text {
            background-color: $odu-light-green;
        }

        .link-area {
            a {
            }

            a:hover {
                background-color: $white;
                border-color: $white;
                color: $odu-light-green;
            }
        }
    }

    &.gray {
        background-color: $odu-gray-blue;
        color: $white;

        .text {
            background-color: $odu-gray-blue;
        }

        .link-area {
            a {
            }

            a:hover {
                background-color: $white;
                border-color: $white;
                color: $odu-gray-blue;
            }
        }
    }



    > .image {
        position: relative;
        @include make-col-ready();

        @include media-breakpoint-up(lg) {
            @include make-col(5);
        }

        img {
            width: 100%;
        }

        .mobile-image {
            display: none;
        }

        .img-el {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top center;
            width: 100%;
            height: 100%;
        }

        @include media-breakpoint-down(md) {
            .mobile-image {
                display: block;
            }

            .img-el {
                background: none !important;
            }
        }

        @include media-breakpoint-up(md) {

            .img-el {
                position: absolute;
            }
        }

        padding-left: 0;
        padding-right: 0;

        min-height: 400px;

        @include media-breakpoint-down(md) {
            min-height: 0px;
        }
    }

    > .text-side {
        @include make-col-ready();

        @include media-breakpoint-up(lg) {
            @include make-col(7);
        }

        padding-right: 0;
        padding-left: 0;
        padding-top: 0;

        .text {
            padding-top: 2em;
            padding-left: 25px;
            padding-right: 25px;
            padding-top: 40px;
            padding-bottom: 8px;

            .heading {
                font-weight: normal;
                color: white;
            }

            p {
                line-height: 2em;
                margin-bottom: 32px;
            }

            a {
                color: white;
            }
        }

        .link-area {
            text-align: right;
            margin-top: 25px;
            margin-right: 50px;
            margin-bottom: 25px;

            a {
                border-width: 4px;
                border-radius: 0px;
                border-color: $white;
                color: $white;
                text-transform: uppercase;
                font-size: 1.2em;
                font-weight: bold;
                padding: 10px 30px;
            }

            @include media-breakpoint-down(sm) {
                text-align: center;
                margin-right: 0;
            }
        }
    }

    &.long-image {

        &.aqua-blue {
            background-color: $odu-aqua-blue-mid-ada;

            .link-area {
                a {
                }

                a:hover {
                    background-color: $white;
                    border-color: $white;
                    color: $odu-aqua-blue-mid;
                }
            }
        }

        &.slate-blue {
            background-color: $odu-slate-blue-dark;

            .link-area {
                a {
                }

                a:hover {
                    background-color: $white;
                    border-color: $white;
                    color: $odu-slate-blue-dark;
                }
            }
        }

        &.green {
            background-color: $odu-dark-green;

            .link-area {
                a {
                }

                a:hover {
                    background-color: $white;
                    border-color: $white;
                    color: $odu-light-green;
                }
            }
        }

        &.gray {
            background-color: $odu-gray-blue;

            .link-area {
                a {
                }

                a:hover {
                    background-color: $white;
                    border-color: $white;
                    color: $odu-gray-blue;
                }
            }
        }


        > .text-side {

            @include make-col-ready();

            @include media-breakpoint-up(lg) {
                @include make-col(4);
            }

            display: flex;
            align-content: center;
            justify-content: center;
            flex-direction: column;

            .text {
                background-color: transparent;
                text-align: center;

                h2, .heading {
                    font-weight: bold;
                    margin-bottom: 10px;
                }

                p:last-child {
                    margin-bottom: 0px;
                }
            }

            .link-area {
                text-align: center;
                margin: 25px;

                a {
                    font-weight: 500;
                }
            }

            padding-right: 0;
            padding-left: 0;
            padding-top: 0;
        }

        > .image {
            @include make-col-ready();

            @include media-breakpoint-up(lg) {
                @include make-col(8);
            }

            padding-right: 0;
            padding-left: 0;
            padding-top: 0;
            min-height: 500px;

            @include media-breakpoint-down(md) {
                min-height: 0px;
            }
        }
    }
}
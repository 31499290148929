﻿/// <reference path = "../../main.scss" />
// File Name: _layout.scss
// Description: Layout/Master view styles
// ------------------------------------------------------------

.subpage.storiesContainer {
    .container-nopadding {
        @include make-container-max-widths();
        margin-left: auto;
        margin-right: auto;

        > .body {
            padding: 0;
            margin: 0;

            > article {
                padding: 0;
                margin: 0;

                .stories-list {
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 15px;

                    .story-item {
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        a.image{
                            border-bottom: 0;
                        }
                        img {
                            width: 165px;
                            margin-right: 15px;
                        }

                        .text-column {
                            .title {
                                /* i need mixin*/
                                text-transform: none;
                                color: $odu-slate-blue;
                                font-size: 24px;
                                margin-bottom: 6px;

                                a {
                                    color: $odu-slate-blue;
                                }
                            }

                            .text {
                                font-size: 16px;
                            }
                        }

                        padding-bottom: 15px;
                        margin-bottom: 15px;
                        border-bottom: 1px solid $odu-dark-gray;

                        &:last-child {
                            border-bottom: none;
                        }

                        @include media-breakpoint-down(xs) {
                            display: block;

                            img {
                                margin-right: 0;
                                margin-bottom: 15px;
                                width: 100%;
                            }
                        }
                    }
                }
            }

            > aside {
                padding: 0;
                margin: 0;
            }
        }
    }
}

﻿/// <reference path = "../../main.scss" />
// File Name: _layout.scss
// Description: Layout/Master view styles
// ------------------------------------------------------------

.subpage {
    .container-inline {
        padding: 1.25rem;
    }

    aside {
        margin-bottom: 2em;
    }

    .card-block {
        padding: 15px;
    }

    main {
        .LargeText {
            line-height: 1.5em;
        }

        .ge-hr {
            margin-top: 3rem;
            background-color: $odu-light-gray-blue;
            margin-bottom: 3rem;
        }
    }
}

/* forms */
.umbraco-forms-field {
    clear: both;

    input[type="file"] {
        margin-top: 10px;
        margin-bottom: 15px;
    }
}
.contourPage {
    input.btn {
        font-family: $headings-font-family;
        text-transform: uppercase;
        border-radius: 0;
        font-size: 16px;
        font-weight: 600;
        background-color: $odu-slate-blue;
        color: $white;
        white-space: normal;

        &:hover {
            cursor: pointer;
            background-color: $odu-slate-blue-hover;
        }
    }
}
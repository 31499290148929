﻿///<reference path="../../main.scss" />
// File Name: _blog.scss
// Description: Styles for the Blog
// ------------------------------------------------------------

//Specific Sidebar styles for blog pages
.ArticulateArchive, .ArticulateTags, .ArticulateRichText, .ArticulateMarkdown {
    .breadcrumbs {
        ul {
            padding-left: 15px;
        }
    }

    .sidebar {
        @include make-col-ready();
        @include make-col(12);

        @include media-breakpoint-up(md) {
            @include make-col(4);
        }

        .sidenav {
            //border: 1px solid $mediumgray;
            padding: 10px;

            .widget {
                padding-bottom: 20px;
            }
        }
    }

    .content-wrapper {
        @include make-col-ready();
        @include make-col(12);

        @include media-breakpoint-up(md) {
            @include make-col(8);
        }
    }
}

//Blog Index Styles
.ArticulateArchive, .ArticulateTags {

    .filter-wrapper {
        padding-bottom: 30px;

        .filter {
            margin-bottom: 0;
        }
    }

    .pagination-wrapper {
        text-align: center;
        -ms-flex: 0 0 100%;
        -webkit-flex: 0 0 100%;
        flex: 0 0 100%;
    }

    .posts {
        padding-top: 35px;

        .post-content {
            &:first-child {
            }

            display: flex;
            -ms-flex-direction: row;
            -webkit-flex-direction: row;
            flex-direction: row;

            @include make-col-ready();
            @include make-col(12);
            padding-bottom: 2rem;
            margin-bottom: 2rem;
            border-bottom: 1px solid rgba(0,0,0,0.2);

            .summary {
                padding-top: 30px;

                p {
                    margin-bottom: 0;
                }
            }

            .author, .categories, .time {
                margin-bottom: 0;

                p {
                    margin-bottom: inherit;
                }
            }

            .img-wrapper {
                @include make-col-ready();
                @include make-col(3);

                + .content-wrapper {
                    @include make-col(9);
                }
            }

            .content-wrapper {
                @include make-col-ready();
                @include make-col(12);
            }
        }
    }
}

﻿.inset-image
{
    max-width: 942px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    img{
           width: 100%;
       }
    .caption
    {
        background-color: $odu-light-gray-blue;
        padding: 10px;
        font-size: 14px;
    }
}
.full-width-image {
    max-width: 942px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: block;
    img
    {
        width: 100%;
        margin-bottom: 0;
    }

    figcaption {
        background-color: $odu-light-gray-blue;
        padding: 10px;
        font-size: 14px;
        font-style: italic;
    }
}
figure.inline-image {
    display: inline-block;
    margin-top: 10px;

    img {
        width: 100%;
    }

    &.float-left {
        float: left;
        margin-right: 20px;
        width: auto;
        clear: both;
    }

    &.float-right {
        float: right;
        margin-left: 20px;
        width: auto;
        clear: both;
    }

    @include media-breakpoint-down(sm) {
        display: block;

        &.float-right, &.float-left {
            float: none !important;
            margin-left: 0;
            margin-right: 0;
        }

        img {
            width: 100%;
        }

        margin-bottom: 25px;
    }

    figcaption {
        background-color: $odu-light-gray-blue;
        padding: 10px;
        font-size: 14px;
        font-style: italic;
        text-align: center;
    }
}

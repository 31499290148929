﻿///<reference path="../../main.scss" />
// File Name: _ie.scss
// Description: Styles for IE
// ------------------------------------------------------------
.ie10below, .ie11 {

    .column.card, .column > .card {
        display: block;
    }

    .color-card {
        max-height: 350px;

        @include media-breakpoint-down(md) {
            max-height: 750px;
        }
    }

    @include media-breakpoint-down(sm) {
        .color-card {
            max-height: none;
        }

        .card {
            display: block;
        }
    }
}
﻿/// <reference path = "../../main.scss" />
// File Name: _layout.scss
// Description: Layout/Master view styles
// ------------------------------------------------------------

.subpage.story {
    .story-content{
        padding-left: 15px;
        padding-right: 15px;

        img{
            max-width: 250px;
        }
    }
}

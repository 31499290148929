﻿.sidebar-cta {
    margin-bottom: 50px;

    .top-title {
        font-family: $headings-font-family;
        text-transform: uppercase;
        color: $odu-aqua-blue-ada;
        font-weight: bold;
        font-size: 23px;
        letter-spacing: -0.01em;
    }

    .text-container {
        background-color: $odu-light-gray;
        padding: 15px;
        position: relative;

        &.has-link {
            padding-bottom: 30px;
        }

        .sub-title {
            font-family: $headings-font-family;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 5px;
        }

        .content {
            font-size: 16px;
            line-height: 1.3;
        }

        .btn-holder {
            width: 100%;
            margin-bottom: -40px;
            text-align: right;
        }

        a.btn {
            display: inline-block;
            font-family: $headings-font-family;
            text-transform: uppercase;
            border-radius: 0;
            font-size: 16px;
            font-weight: 600;
            white-space: normal;
            margin-right: -15px;
        }

        .section-content {
            font-size: 16px;
            line-height: 1.3;
            border-bottom: 1px solid $odu-dark-gray;
            margin-bottom: 25px;

            p:last-child {
                margin-bottom: 25px;
            }

            &:last-child {
                border-bottom: none;
                margin-bottom: 0px;

                p:last-child {
                    margin-bottom: 20px;
                }
            }
        }
    }

    img {
        width: 100%;
    }

    @include media-breakpoint-down(md) {
        margin-top: 50px;
    }
    /*& + .sidebar-cta {
        margin-top: 50px;
    }*/
}

﻿/// <reference path = "../../main.scss" />
// File Name: _home.scss
// Description: Home view styles
// ------------------------------------------------------------

.home {
    main {
        padding-top: 0;

        .banner-holder {
            @include media-breakpoint-down(md) {
                margin-bottom: 2rem;
            }
        }
    }
}
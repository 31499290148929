﻿/// <reference path = "../../main.scss" />
// File Name: _footer.scss
// Description: footer view styles
// ------------------------------------------------------------

footer {
    border-top: none;
    background-color: $odu-slate-blue-dark;
    color: $white;

    .mobile-overlay.show {
        z-index: 1;
    }

    .footer-holder {
    }

    h3, .title, .h3Subheading {
        text-transform: uppercase;
    }

    a {
        color: $odu-teal-blue-link;
        border-bottom: 1px dotted $odu-teal-blue-link;

        &:hover {
            color: $odu-teal-blue-link;
            text-decoration: none;
            border-bottom: 1px solid $odu-teal-blue-link;
        }
    }

    .disclaimer {
        text-align: right;
        font-size: 12px;
        color: $odu-gray-blue-disclaimer-ada;
        border-top: 1px solid $odu-darker-gray;
        padding-top: 5px;
        margin-top: 30px;

        @include media-breakpoint-down(sm) {
            text-align: left;
        }
    }

    .footer-columns {

        @include make-row();
        margin-left: 0px;
        margin-right: 0px;

        .give-now-holder {

            @include make-col-ready();
            /*@include media-breakpoint-up(lg) {
                @include make-col(5);
            }

            @include media-breakpoint-up(xl) {
                @include make-col(4);
            }

            @media (min-width: 1400px) {
                @include make-col(4);
            }

            @media (min-width: 1660px) {
                @include make-col(3);
            }*/
            @include media-breakpoint-up(lg) {
                width: 410px;
            }

            margin-top: -32px;

            @include media-breakpoint-down(md) {
                margin-top: 0px;
            }

            background-color: $odu-dark-green-ada;
            color: white;
            padding: 30px;

            h3, .title, .h3Subheading {
                text-align: center;
            }

            .inner-text {
                font-size: 1em;
                margin-bottom: 0.5em;
                font-weight: 300;
            }

            .inner-btns {
                text-align: center;

                .btn {
                    color: $white;
                    font-family: $headings-font-family;
                    background-color: $odu-light-green-ada;
                    display: block;
                    font-size: 18px;
                    font-weight: bold;
                    padding: 0.875rem 0.75rem;
                    border-radius: 0;
                    border-bottom: none;
                    box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);

                    &:hover, &:focus {
                        background-color: $white;
                        color: $odu-light-green-ada;
                    }
                }

                .other {
                    font-family: $headings-font-family;
                    color: $white;
                    display: inline-block;
                    font-size: 20px;
                    font-weight: 600;
                    padding: 0;
                    margin: 0.875rem 0.8rem 0 0.8rem;
                    border-bottom: 1px dotted $white;

                    &:hover, &:focus {
                        color: $white;
                        text-decoration: none;
                        border-bottom: 1px solid $white;
                    }

                    @include media-breakpoint-down(xs) {
                        font-size: 18px;
                    }
                }
            }
        }

        &.has-give-now {
            .footer-right {
                /*@include make-col-ready();

                @include media-breakpoint-up(lg) {
                    @include make-col(7);
                }

                @include media-breakpoint-up(xl) {
                    @include make-col(8);
                }

                @media (min-width: 1400px) {
                    @include make-col(8);
                }

                @media (min-width: 1660px) {
                    @include make-col(9);
                }*/
                /*width: 100%;*/
                //@include media-breakpoint-up(lg) {
                flex: 1 1 auto;
                //}
            }
        }

        .footer-right {
            display: flex;
            align-items: center;
        }

        .footer-right .footer-right-content {
            padding: 40px 20px 40px 20px;
            font-size: 16px;
            width: 100%;


            .flex-container {
                display: flex;
                justify-content: space-between;

                > .footer-paragraph {
                    width: 600px;

                    @media (max-width: 1550px) {
                        width: 400px;
                    }

                    @media (min-width: 992px) and (max-width: 1076px) {
                        width: 300px;
                    }
                }

                @media (max-width: 660px) {
                    display: block;

                    > .footer-paragraph {
                        width: 100%;
                    }
                }
            }

            h3, .title, .h3Subheading, .nav-wrapper > ul > li > a, .nav-wrapper > ul > li > span.title-no-link {
                font-family: $headings-font-family;
                font-size: 20px;
                font-weight: 600;
                text-transform: uppercase;
            }

            .footer-paragraph {
                margin-bottom: 30px;
            }

            .footer-navigation-address {
                display: flex;


                .footer-navigation {
                    flex: 1 1;

                    .nav-wrapper > ul > li > a, .nav-wrapper > ul > li > span.title-no-link {
                        color: $white;
                        margin-bottom: 0rem;
                    }

                    .nav-link:hover {
                        text-decoration: underline;
                    }

                    .nav-wrapper > ul > li > span.title-no-link:hover {
                        text-decoration: none;
                    }

                    ul {
                        margin: 0;
                        padding: 0;

                        li {
                            list-style-type: none;
                            margin-bottom: 5px;
                            line-height: 1.4;

                            .nav-link {
                                color: $white;
                                padding: 0;
                                border: none;
                            }
                        }

                        li.nav-item.root-item {
                            display: inline-block;
                            vertical-align: top;

                            @include media-breakpoint-up(xs) {
                                @include make-col(12);
                            }

                            @include media-breakpoint-up(sm) {
                                @include make-col(6);
                            }

                            @include media-breakpoint-up(lg) {
                                @include make-col(5);
                            }

                            @include make-col-ready();
                            margin-top: 30px;

                            > .nav-link {
                                text-transform: uppercase;
                            }
                        }
                    }
                }

                .footer-address {
                    /*margin-top: 30px;*/
                    text-align: right;

                    h3, .title, .h3Subheading {
                        margin-bottom: 0;
                    }

                    .h3Subheading {
                        margin-bottom: 1rem;
                    }

                    a {
                        color: $white;
                        border-color: white;
                    }
                }

                @include media-breakpoint-down(sm) {
                    display: block;

                    .footer-address {
                        text-align: left;
                    }

                    .footer-navigation ul li.nav-item.root-item {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }



            .social-media {
                text-align: right;

                ul li {
                    list-style-type: none;
                    display: inline-block;
                    padding: 1em;
                    padding-top: 10px;
                    padding-right: 0;

                    a {
                        color: $white;

                        .fa {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

﻿.fundraising-committee-block-grid-editor {
    background-color: $odu-slate-blue-dull;
    color: white;
    padding: 50px 15px 100px 15px;
    margin-left: -15px;
    margin-right: -15px;

    .inner {
        @include make-container();
        //@include make-container-max-widths();
        h2, h3 {
            text-align: center;
            color: $white;
        }

        h3 {
            font-size: 18px;
            margin-bottom: 3em;
        }

        .members-list {
            @include make-row();

            .member {
                /*font-family: $font-family-alternate;*/
                @include make-col-ready();

                @include make-col(12);

                @include media-breakpoint-up(md) {
                    @include make-col(6);
                }

                @include media-breakpoint-up(lg) {
                    @include make-col(4);
                }

                font-size: 17px;
                font-family: $headings-font-family;
                color: $odu-fundraising-member-gray;
                text-align: center;
                margin-bottom: 15px;
            }
        }
    }

    @include media-breakpoint-down(md) {
        padding-bottom: 50px;
    }
}

﻿/// <reference path = "../../main.scss" />
// File Name: _search.scss
// Description: Styles for the Search Grid Editor
// ------------------------------------------------------------

article {
    &.content {
        .result {
            h4 {
                margin-top: 0;
            }
        }
    }
}

.search-component {
    padding-bottom: 1rem;

    .form-control {
        &:focus, &:active {
            border-color: rgba(0, 0, 0, 0.15);
        }
    }

    h4 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: .25rem;
        font-weight: 600;
    }

    p {
        margin-bottom: 0;
    }

    .message {
        padding-top: 1rem;
    }

    .search-results {
        padding-top: 1rem;

        .result {
            padding-bottom: 1rem;
        }
    }
}

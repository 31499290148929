﻿/// <reference path = "../../main.scss" />
// File Name: _button.scss
// Description: Button styles
// ------------------------------------------------------------
main a.btn {
    background-color: $odu-slate-blue;
    color: $white;
    
    &:hover {
        background-color: $odu-slate-blue-hover;
    }
    &:active {
        background-color: $odu-slate-blue-active;
    }

    &.btn-outline-primary {
        background-color: transparent;
    } 

    &.btn.aqua-blue {
        background-color: $odu-aqua-blue;

        &:hover {
            background-color: $odu-aqua-blue-dark;
        }
    }

    &.btn.green {
        background-color: $odu-light-green;

        &:hover {
            background-color: $odu-dark-green;
        }

        &:active {
            background-color: $odu-light-green-active;
        }
    }

    &.btn.gray {
        background-color: $odu-dark-gray;

        &:hover {
            background-color: $odu-darker-gray;
        }

        &:active {
            background-color: $odu-dark-gray;
        }
    }
}
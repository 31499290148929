﻿.sidebar-nav {
    margin-bottom: 40px;

    .top-title {
        font-family: $headings-font-family;
        text-transform: uppercase;
        color: $odu-aqua-blue-ada;
        font-weight: bold;
        font-size: 23px;
        letter-spacing: -0.01em;
    }

    .text-container {
        background-color: $odu-light-gray;
        padding: 15px;
        position: relative;


        .content {
            font-size: 16px;
            line-height: 1.3;

            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;

                    a {
                        color: $black;
                        padding: 12px 0 12px 0;
                        display: block;
                        border-bottom: 1px solid $odu-dark-gray;

                        &.active {
                            color: $odu-light-green-nav-ada;
                        }

                        &:hover {
                            color: $odu-light-green-active;
                        }
                    }
                }

                li:last-child a {
                    border-bottom: none;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
    }
    /*& + .sidebar-cta {
        margin-top: 50px;
    }*/
}

﻿/// <reference path = "../../main.scss" />
// File Name: _widget-color-cards.scss
// Description: Styles for the Grid Editor
// ------------------------------------------------------------




.color-card {
    color: $white;
    margin-top: 30px;
    margin-bottom: 50px;
    transition: all 0.3s;

    .card-inner {
        position: relative;
        overflow: hidden;
        transform: translate3d(0, 0, 0);

        .image {
            width: 100%;
            transition: all 0.3s;
        }

        .text {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;


            .inner-text {
                padding: 15px;
                flex: 1 1;
                font-size: 1.25em;
                font-weight: 300;

                .card-title {
                    text-transform: uppercase;
                    font-size: 1.5em;
                    font-weight: bold;
                    color: $white;
                }

                p {
                    line-height: 1.5em;
                }

                a.entire-link {
                    color: $white;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    &:hover {
        .text {
            background-color: rgba(0,0,0, 0.0);
        }

        .card-inner {

            .image {
                filter: saturate(140%) brightness(120%);
                transform: scale(1.05);
                margin-left: 0%;
                margin-top: 0%;
                backface-visibility: hidden;
            }
        }
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 0;
        margin-top: 30px;
    }

    @include media-breakpoint-down(xs) {
        .card-inner .text .inner-text .card-title {
            font-size: 1.6rem;
        }
    }
}

@include media-breakpoint-down(md) {
    .body > .column-4.card:last-child .color-card {
        margin-bottom: 30px;
    }
}

@include media-breakpoint-down(sm) {
    .column-4.widgetPicker > .color-card {
        margin-left: -15px;
        margin-right: -15px;
    }

    .column.column-4.widgetPicker + .column.column-4.widgetPicker {
        > .color-card
        {
            margin-top: 0px;
        }
    }
}
﻿/// <reference path = "../marathon.scss" />
// File Name: _cards.scss
// Description: Cards Styles
// ------------------------------------------------------------

.card {
    &.column, &.row {
        margin-bottom: 0;
        background-color: transparent;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        border-radius: 0;
        border: none;
    }
}

//Show cards within card decks at full-width, and then 2 column on tablets, and 3 columns on computers
.card-deck {
    display: flex;
    justify-content: flex-start;
    -ms-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    align-items: stretch;
    //Reset the card styles to provide more flexibility - this div is now used for the layout & padding and .card-inner can control bg-color or border styles
    .card {
        display: block;
        max-width: 100%;
        flex-basis: 100%;
        -ms-flex: unset;
        -webkit-flex: unset;
        flex: unset;
        margin-right: 0;
        margin-left: 0;
        background-color: transparent;
        border: none;
        padding: 0 15px 15px;
        //This media style is how to have the flex layout show 2 divs at 50% width and then stretch the 3rd to be as wide as the 2 above on the medium breakpoint)
        //It's important to note that when flex is used like immediate below in the mixin, flex-basis will control how wide the elements should be, but a max-width will contstrain later wrapped flex elements
        @include media-breakpoint-between(md, lg) {
            -ms-flex: 1 0 0;
            -webkit-flex: 1 0 0;
            flex: 1 0 0;
        }
        //This div is the actual card wrapper
        .card-inner {
            height: 100%;
            -moz-border-radius: .25rem;
            -webkit-border-radius: .25rem;
            border-radius: .25rem;
            border: 1px solid rgba(0,0,0,0.125);
        }

        @include media-breakpoint-up(md) {
            flex-basis: 50%;
        }

        @include media-breakpoint-up(lg) {
            flex-basis: 33%;
            max-width: 33%;
        }
    }
}

.card-img {
    width: 100%;
}

.card-img-top {
    img {
        width: 100%;
    }
}

.card-banner {
    .card-title {
        color: $white;
        text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    }
}

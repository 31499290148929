﻿.contourFieldSet .row-fluid {
    @include make-row();
}

.contourPage {
    margin-top: 20px;
    margin-bottom: 20px;
}

#contour, .contour {
    .contourFieldSet {
        padding-bottom: 0;
        margin-bottom: 0;
        padding-top: 0;
    }

    input.text, textarea {
        max-width: 100% !important;
        width: 100%;
    }

    textarea {
        height: 150px;
    }

    .umbraco-forms-field {
        margin-bottom: 30px;
        &.recaptcha
        {
            >label{
                display: none;
            }
        }
    }

    .contourNavigation .col-md-12 {
        padding-left: 0;
        padding-right: 0;
    }
}



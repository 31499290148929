﻿/// <reference path = "../../main.scss" />
// File Name: _nav-primary.scss
// Description: Primary navigation view styles
// ------------------------------------------------------------

nav.nav-primary {
     border-bottom: 0px; 
}
nav.nav-primary nav .navbar-brand {
    padding-top: 0;
}
nav.nav-primary nav .nav-wrapper {
    background-color: $odu-header-gray;
    height: 100px;
    padding-right: 0px;

    .navbar-brand {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 0;
        padding-left: 0;
        padding-bottom: 5px;
        /*margin-left: -15px;
        margin-top: -50px;
        background-color: $white;*/
    }

    > .navbar-expand-md {
        flex: 1;
    }

    #primary-collapsing-navbar .navbar-nav > .nav-item.dropdown > .nav-link:after {
        display: none;
    }

    #primary-collapsing-navbar .navbar-nav > .nav-item.current .nav-link span.text {
        border-bottom: 2px solid $odu-slate-blue-active;
    }

    #primary-collapsing-navbar .navbar-nav > .nav-item.dropdown .dropdown-menu a.dropdown:after {
        color: $white;
    }

    #primary-collapsing-navbar .navbar-nav .dropdown {
        position: relative;
    }

    #primary-collapsing-navbar .navbar-nav > .nav-item.dropdown {
        position: relative;
    }
    /* Third level */
    #primary-collapsing-navbar .navbar-nav > .nav-item.dropdown > .dropdown-menu > ul > li > .dropdown-menu {
        min-width: 280px;
        font-size: 0.9em;
        line-height: 1.7em;

        & > ul > li > a {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    /* Last Third Level */
    #primary-collapsing-navbar .navbar-nav > .nav-item.dropdown:last-child > .dropdown-menu > ul > li > .dropdown-menu {
        min-width: 200px;
    }

    #primary-collapsing-navbar .navbar-nav .nav-item, #primary-collapsing-navbar > .navbar-nav .nav-item {
        transition: all 0.2s;

        .nav-link {
            color: $odu-black;
            font-weight: 400;
            font-size: 1.00em;
            font-family: $headings-font-family;

            &:hover {
                background-color: transparent;
            }
        }



        &:hover .dropdown:after {
            color: $white;
        }

        &:hover {
            background-color: $odu-highlight-blue;

            > .nav-link {
                color: $white;

                span.text {
                    border-bottom: 2px solid $white;
                }
            }
        }


        &.dropdown .dropdown-menu {
            background-color: $odu-highlight-blue-opacity;
            border: none;
            border-radius: 0px;
            padding: 0px;
            box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
            min-width: 280px;

            > ul > li {
                padding: 0px;

                a {
                    color: $white;
                    padding: 10px 1.5rem;
                    font-weight: bold;
                }

                &:hover {
                    background-color: $odu-highlight-blue;

                    > a {
                        color: $white;
                    }
                }
            }
        }
    }



    #primary-collapsing-navbar .navbar-nav > .nav-item > .nav-link {
        color: $odu-black;
        font-weight: 800;
        font-size: 18px;
        font-family: $headings-font-family;
    }



    #primary-collapsing-navbar .navbar-nav > .nav-item {
        > .nav-link {
            padding: 36px 1.5rem 37px 1.5rem;
        }
    }

    #primary-collapsing-navbar .navbar-nav .nav-item.dropdown .dropdown-menu > ul > li a {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .give-now-link {
        background-color: $odu-dark-green-ada;
        padding: 0.9em 1.5rem;
        padding: 22px 1.5rem;
        margin-left: 1.5rem;
        height: 100px;

        > a {
            font-family: $headings-font-family;
            color: $white;
            background-color: $odu-light-green-ada; /*$odu-light-green;*/
            padding: 14px 2rem;
            transition: all 0.15s ease-in-out;
            text-decoration: none;
            display: inline-block;
            font-weight: bold;
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);

            &:hover, &:focus {
                background-color: $white;
                color: $odu-light-green-ada;
            }
        }
    }
}


    /* Custom for overflowing primary nav*/
    @media only screen and (max-width: 1380px) {
        .navbar-brand {
            margin-right: 0;
        }

        nav.nav-primary nav .nav-wrapper #primary-collapsing-navbar .navbar-nav > .nav-item, header .nav-wrapper .give-now-link {
            .nav-link {
                font-size: 16px;
            }

            > .nav-link {
                padding: 38px 1rem;
            }

            &.heading {
                padding: 22px 1rem;

                > .nav-link {
                    color: $white;
                    padding: 14px 1rem;
                }
            }
        }

        /* Third level */
        #primary-collapsing-navbar .navbar-nav > .nav-item.dropdown > .dropdown-menu > ul > li > .dropdown-menu > ul > li > a.nav-link {
            line-height: 1.6;
            font-size: 15px;
        }

        nav.nav-primary nav .nav-wrapper #primary-collapsing-navbar .navbar-nav .nav-item.dropdown .dropdown-menu ul li a {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }

        nav.nav-primary nav .nav-wrapper .give-now-link {
            margin-left: 0;
        }
    }

    @media only screen and (max-width: 1152px) {
        nav.nav-primary nav .nav-wrapper #primary-collapsing-navbar .navbar-nav > .nav-item, nav.nav-primary nav .nav-wrapper .give-now-link {
            .nav-link {
                font-size: 14px;
                padding: 39px 0.625rem 40px 0.625rem;
            }

            &.give-now-link {
                margin-left: 10px;

                .nav-link {
                    padding: 14px 1rem;
                }
            }
        }

        nav.nav-primary nav .nav-wrapper #primary-collapsing-navbar .navbar-nav .nav-item.dropdown .dropdown-menu ul li a {
            padding-left: 1.0rem;
            padding-right: 1.0rem;
        }
    }
/* Tablet */
@include media-breakpoint-down(lg) {
    #primary-collapsing-navbar .navbar-nav > .nav-item > .nav-link, header .navbar-wrapper .give-now-link {
        font-size: 16px;
    }
    /* Third level */
    #primary-collapsing-navbar .navbar-nav > .nav-item.dropdown > .dropdown-menu > ul > li > .dropdown-menu > ul > li > a.nav-link {
        line-height: 1.5;
        font-size: 14px;
    }

    nav.nav-primary nav .nav-wrapper #primary-collapsing-navbar .navbar-nav .nav-item.dropdown .dropdown-menu ul li a {
        padding-left: 1.0rem;
        padding-right: 1.0rem;
    }
}
    /* Mobile */
    .mobile-header-wrapper {
        .give-now-link {
            display: none;
        }
    }

@include media-breakpoint-down(md) {
    nav.nav-primary nav .mobile-header-wrapper {
        flex-wrap: nowrap;
    }

    .mobile-header-wrapper {

        .give-now-link {
            display: flex;
            background-color: $odu-dark-green;
            padding: 8px 10px;
            margin-left: 0;
            font-size: 14px;
            height: 100%;
            margin-left: auto;
            align-items: center;
            text-align: center;

            > a {
                font-family: $headings-font-family;
                color: $white;
                background-color: $odu-light-green;
                padding: 10px 10px;
                transition: all 0.15s ease-in-out;
                text-decoration: none;
                display: flex;
                font-weight: bold;
                padding-top: 0;
                padding-bottom: 0;
                align-items: center;
                min-height: 40px;

                &:hover, &:focus {
                    background-color: $white;
                    color: $odu-light-green;
                }
            }
        }
    }

    .nav-utility {
        display: none;
        height: 30px;
        font-size: 13px;

        .navbar {
            padding: 0px;

            .navbar-nav {
            }
        }
    }

    nav.nav-primary nav .mobile-header-wrapper {
        height: 57px;
        background-color: $odu-header-gray;

        .navbar-brand {
            height: 100%;
            margin-top: 0px;


            img {
                max-height: 100%;
                padding: 8px;
            }

            @include media-breakpoint-down(sm) {
                margin-left: auto;
                padding-left: 0;
            }
        }

        .navbar-toggler {
            padding-left: 0;
            padding-right: 0;
            background-color: $odu-slate-blue-dark;
            color: $white;
            width: 75px;

            span.hamburger {
                font-size: 28px;
                line-height: 32px;
            }

            span.title {
                font-size: 12px;
                margin-top: -0px;
                display: block;
            }

            &.closeLabel {
                font-weight: bold;
                font-size: 52px;
                line-height: 52px;
                padding-top: 0;
            }
        }
    }

    #menu {
        height: calc(100vh - 97px) !important;
        max-height: calc(100vh - 97px);
    }

    #menu .multilevelpushmenu_wrapper {
        margin-left: 10%;
        overflow-y: auto;
    }

    #menu .multilevelpushmenu_wrapper .levelHolderClass {
        background-color: $odu-slate-blue-dark;

        .h2 {
            margin-bottom: 0;
            display: table;
            width: 100%;
            display: none;
        }
    }


    #menu .multilevelpushmenu_wrapper li {
        &, li.primary-nav-link {
            background-color: $odu-slate-blue-dark;
            border-top: 0px;

            &:hover, &:active {
                background-color: transparent;
            }

            &:first-child {
                border-top: 0px;
                padding-top: 12px;

                a {
                    padding-top: 8px;
                }
            }

            &:last-child {
                border-bottom: 0px;
            }

            a {
                font-size: 20px;
                color: $white;
                font-weight: bold;
                text-transform: none;
                padding: 8px 20px;
            }
        }

        &:hover > a {
            color: $white;
            text-decoration: none;
            background-color: $odu-highlight-blue;
        }

        .backItemClass {
            background-color: $odu-slate-blue;
            border-top: 0px;


            > a, > a:focus, > a:active, > a:hover {
                color: $white;
                text-decoration: none;
                padding-top: 8px;
            }
        }
        /*mobile utility menu*/
        &.mobile-utility-break {
            padding: 20px 0px 0 0px;
            margin: 0 20px 20px 20px;
            border-bottom: 2px solid $odu-darker-gray;
        }

        &.mobile-utility-link {
            a {
                font-weight: lighter;
            }

            &:last-child a {
                padding-bottom: 20px;
            }
        }
    }
}

    @include media-breakpoint-down(sm) {
        .nav-utility {

            .navbar {

                .navbar-nav {
                    padding-right: 5px;

                    li + li {
                        margin-left: 0px;
                    }

                    a.nav-link {
                        padding: 0 0.45em;
                    }
                }
            }
        }
    }
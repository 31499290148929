﻿/// <reference path = "../../main.scss" />
// File Name: _nav-utility.scss
// Description: Utility navigation styles
// ------------------------------------------------------------
.nav-utility 
{
    height: 50px;
    .navbar {
        padding: 13px 10px;
        background-color: $odu-slate-blue-dark !important;

        a.nav-link {
            color: $white;
            padding: 0 1em;
            display: inline-block;

            &:hover {
                color: $white;
                text-decoration: underline;
            }
        }

        li.nav-item {
            &::after {
                display: inline-block;
                color: $odu-dark-gray;
                font-size: 0.75em;
                content: "|";
                line-height: 1em;
                vertical-align: middle;
                margin-top: -5px;
            }

            &:last-child::after {
                content: "";
            }
        }
    }

}
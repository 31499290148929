﻿.accordion-content {
    margin-bottom: 50px;

    .accordion-item {
        margin-bottom: 25px;
        background-color: $odu-header-gray;
        border: 3px solid $odu-light-gray;
        font-size: 16px;
        display: flex;
        flex-direction: row;


        &.has-image {
            .text-section {
            }
        }

        .image {
            width: 164px;
            display: inline-block;
            flex-shrink: 0;

            img {
                width: 100%;
            }
        }

        .text-section {
            padding: 12px 30px 20px 30px;
            position: relative;
            transition: all 0.2s;

            .title {
                color: $odu-slate-blue;
                text-transform: none;
                font-size: 25px;
                margin-bottom: 6px;
                margin-right: 92px;
            }

            a.expand-collapse {
                background-color: $odu-slate-blue;
                color: $white;
                position: absolute;
                top: -3px;
                right: -3px;
                padding: 5px 15px;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 18px;
                padding-right: 15px;
                width: 125px;
                text-align: center;
                display: none;

                span {
                    vertical-align: middle;
                    display: inline-block;
                }

                &::after {
                    content: "\f107";
                    font-family: FontAwesome;
                    font-size: 25px;
                    margin-left: 15px;
                    line-height: 1em;
                    display: inline-block;
                    vertical-align: middle;
                    position: absolute;
                    top: 7px;
                    right: 15px;
                }
            }

            .content .additional-content {
                height: 1px;
                transition: all 0.2s;
            }
        }

        &.has-more-content {
            .text-section a.expand-collapse {
                display: block;
            }
        }

        &.collapsed {

            .text-section .content .additional-content {
                overflow: hidden;
            }

            .text-section .first-para h3 {
                margin-top: 1rem;
            }

            a.expand-collapse {
                .expanded {
                    display: none;
                }
            }
        }

        &.expanded {
            .text-section {
                padding-bottom: 30px;

                .content .additional-content {
                    /*overflow: initial;*/
                }
            }

            a.expand-collapse {
                .collapsed {
                    display: none;
                }

                &::after {
                    content: "\f106";
                }
            }
        }

        @include media-breakpoint-down(sm) {
            display: block;
            border: none;

            .image {
                width: 100%;
            }

            .text-section {
                border: 3px solid $odu-light-gray;
                position: relative;
                padding-top: 60px;

                .title {
                    margin-right: 0;
                }
            }
            /*
                display: block;
            position: relative;

            .image {
                width: 60%;
                margin-top:-3px;
                width: calc(100% - 125px);
            }

            .text-section {
                position: initial;

                .title {
                    margin-right: 0;
                }
            }
            */
        }
    }
}

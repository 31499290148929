﻿.responsive-embed {
    margin-bottom: 1.65rem;

    .embed-responsive {
        margin-left: auto;
        margin-right: auto;
    }

    .embed-responsive-custom {
        padding-bottom: 56.25%;
    }

    &.embed-left {
        .embed-responsive {
            margin-left: 0;
        }
    }

    &.embed-right {
        .embed-responsive {
            margin-right: 0;
        }
    }

    &.embed-center {
        .embed-responsive {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

﻿.faq-list-items {
    padding-top: 15px;

    .item {
        padding-left: 45px;
        margin-bottom: 30px;
        position: relative;

        .title {
            color: $odu-slate-blue;
            text-transform: none;
            text-align: left;
            font-size: 19px;
            line-height: 1.3;
            margin-bottom: 5px;
        }

        .content {
        }

        &::after {
            font-family: "FontAwesome";
            content: "\f059";
            color: $odu-aqua-blue-mid;
            position: absolute;
            top: -8px;
            left: 0px;
            font-size: 24px;
        }
    }
}

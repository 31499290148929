﻿/// <reference path = "../../main.scss" />
// File Name: _layout.scss
// Description: Layout/Master view styles
// ------------------------------------------------------------
body{
    font-size: 18px;
}

header {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 3;
    background-color: white;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
}
a.skip-content {
    position: absolute;
    left: -10000px;
    top: 5px;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

a.skip-content:focus {
    position: absolute;
    width: auto;
    height: auto;
    left: 5px;
    padding-right: 5px;
        
    background-color: $black;
    top: 5px;
    z-index: 1032;
}       
main {
    margin-top: 180px;
    padding-top: 0;
    min-height: 400px;
    z-index: 1;
    padding-bottom: 32px;

    .body {
        min-width: 100%;
    }

    .headline h1, .headline h2 {
        text-align: center;
    }

    .column-9 {
        .headline h1, .headline h2, .headline h3, .headline h4 {
            text-align: left;
        }
    }

    .headline {
        h1, h2, h3, h4 {
            &.left {
                text-align: left;
            }

            &.center {
                text-align: center;
            }

            &.right {
                text-align: right;
            }
        }

        h3 {
            color: $odu-aqua-blue-dark;
            text-transform: none;
            font-size: 21px;
            font-weight: bold;
        }
    }

    @include media-breakpoint-down(xs) {
        .headline {
            h1 {
                font-size: 1.7rem;
                line-height: 1.3;
            }

            h2 {
                font-size: 1.6rem;
            }

            h3 {
                font-size: 20px;
            }
        }

        h2 {
            font-size: 1.6rem;
        }
    }

    .breadcrumbs {
    }

    @include media-breakpoint-down(md) {
        margin-top: 57px;
    }

    .LargeText {
        font-size: 1.21em;
        line-height: 1.75em;
    }

    .H1StyleAquaBlue {
        font-family: $headings-font-family;
        font-size: 2.5rem;
        font-weight: bold;
        color: #1099cd;
        line-height: 1.0;
    }

    .ListHalfWidth::before {
        content: "\2022";
        margin-right: 0;
        font-size: 22px;
        line-height: 1em;
        position: absolute;
        left: 0px;
    }

    .ListHalfWidth {
        width: 49.5%;
        display: inline-block;
        padding-left: 15px;
        position: relative;

        @include media-breakpoint-down(sm) {
            width: auto;
            display: block;
        }
    }

    .ListHalfWidth + p {
        margin-top: 15px;
    }

    a {
        color: $odu-text-link;
        border-bottom: 1px dotted $odu-text-link;

        &:hover {
            text-decoration: none;
            border-bottom: 1px solid $odu-text-link;
        }

        &[href^="tel:"] {
            font-weight: bold;
        }
    }

    .light-gray {
        background-color: $odu-lighter-gray-blue;
    }

    .negative-bottom-margin {
        margin-bottom: -32px;
    }
}


[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

h1, .h1 {
    font-size: 2.0rem;
}
h2, .h2 { font-size: 1.8rem; }
h3, .h3 { font-size: 1.8rem; }
h4, .h4 { font-size: 1.8rem; }

h1 {
    font-weight: bold;
    text-transform: uppercase;
    color: $odu-black;
}
h2, .h2 {
    font-weight: bold;
    text-transform: uppercase;
    color: $odu-slate-blue-dark;

    &.black {
        color: $odu-black;
    }
}

h1, h2, .h2, h3, .h3 {
    &.blue {
        color: $odu-slate-blue;
        text-transform: none;
        font-size: 23px;
    }
}


p {
    line-height: 1.65em;
    img {
        @extend .img-fluid;
    }
}
.media{
    align-items: unset;
    display: initial;
}



.breadcrumbs {
    margin-bottom: 30px;
    ul {
        list-style-type: none;
        padding-left: 30px;
        padding-right: 30px;

        li {
            display: inline-block;
            font-size: 14px;

            + li {
                padding-left: 8px;

                &:before {
                    content: "\f105";
                    font-family: "FontAwesome";
                    font-size: 12px;
                    padding-right: 15px;
                }
            }
        }
    }
}

﻿.sidebar-text {
    margin-bottom: 50px;

    .top-title {
        font-family: $headings-font-family;
        text-transform: uppercase;
        color: $odu-aqua-blue-ada;
        font-weight: bold;
        font-size: 23px;
        letter-spacing: -0.01em;
    }

    .text-container {
        background-color: $odu-light-gray;
        padding: 15px;
        padding-top: 20px;
        position: relative;


        .section-content {
            font-size: 16px;
            line-height: 1.3;
            border-bottom: 1px solid $odu-dark-gray;
            margin-bottom: 25px;

            p:last-child {
                margin-bottom: 25px;
            }

            &:last-child {
                border-bottom: none;
                margin-bottom: 0px;

                p:last-child {
                    margin-bottom: 20px;
                }
            }
        }


        .sub-title {
            font-family: $headings-font-family;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 5px;
        }
    }

    @include media-breakpoint-down(md) {
    }
    /*& + .sidebar-cta {
        margin-top: 50px;
    }*/
}

﻿///<reference path="../../main.scss" />
// File Name: _banner.scss
// Description: Styles for the Subpage Banner
// ------------------------------------------------------------

main {

    .banner-holder {
        position: relative;
        margin-bottom: 2rem;
        margin-top: -30px;

        @include media-breakpoint-down(md) {
            margin-top: 0px;
        }

        .nav-items {
            position: absolute;
            bottom: 0px;
            width: 100%;
            color: $white;
            overflow: hidden;
            height: 121px;

            .nav-holder {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .bg {
                position: absolute;
                top: 5px;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0.75);
                z-index: 0;
            }

            a {
                position: relative;
                z-index: 1;
                color: $white;
                display: flex;
                padding: 10px 10px;
                font-weight: bold;
                flex: 1 1;
                align-items: center;
                justify-content: center;
                text-align: center;
                border-top: 5px solid transparent;
                height: 121px;
                transition: all 0.2s;
                border-bottom: none;

                &:hover {
                    text-decoration: none;
                    background-color: rgba(0,0,0,0.75);
                    border-color: $odu-aqua-blue;
                }

                .fa {
                    display: block;
                    width: 100%;
                    text-align: center;
                    margin-bottom: 6px;
                    font-size: 1.75em;
                    font-weight: normal;
                }

                .icon-image {
                    display: block;
                    width: 100%;
                    text-align: center;
                    height: 24px;
                    margin-bottom: 10px;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                &.nav-parent {
                    background-color: $odu-slate-blue;
                    font-family: $headings-font-family;
                    color: $white;
                    font-weight: normal;
                    font-size: 29px;
                    border-top: 5px solid $odu-aqua-blue;
                    line-height: 1.1em;
                    padding: 30px 20px;
                    letter-spacing: -0.02em;

                    strong {
                        letter-spacing: 0.03em;
                    }

                    .mobile-holder {
                        display: none;
                    }

                    @include media-breakpoint-down(md) {
                        font-size: 25px;

                        .holder {
                            display: none;
                        }

                        .mobile-holder {
                            display: block;

                            &:after {
                                font-family: Fontawesome;
                                content: "\f105";
                                font-weight: bold;
                                margin-left: 0.34em;
                            }
                        }
                    }
                }
            }

            @include media-breakpoint-down(md) {
                .bg {
                    top: 0px;
                }

                & {
                    height: auto;
                    overflow: inherit;
                    position: relative;

                    .nav-holder {
                        position: relative;
                        display: flex;
                        flex-flow: row wrap;
                        align-items: center;
                        justify-content: center;
                    }

                    a {
                        position: relative;
                        z-index: 1;
                        color: $white;
                        display: flex;
                        display: none;
                        padding: 10px 10px;
                        font-weight: bold;
                        flex: 50%;
                        border-top: 0px;
                        border: 1px solid #2f2f2f;

                        &:hover {
                            border: 1px solid transparent;
                        }
                    }

                    a.nav-parent {
                        display: block;
                        flex: 100%;

                        &:hover {
                            border-top: 5px solid $odu-aqua-blue;
                        }

                        @include media-breakpoint-down(md) {
                            height: auto;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .mobile-holder {
                                border-bottom: 2px solid transparent;
                            }

                            &:hover .mobile-holder {
                                border-bottom: 2px solid $white;
                            }
                        }
                    }
                }
            }
        }

        .banner {
            position: relative;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top center;
            position: relative;
            height: 100vh;
            height: calc(100vh - 150px);
            min-height: 400px;

            @include media-breakpoint-down(lg) {
                /*height: calc(85vh - 57px);*/
                min-height: 400px;
                height: 600px;
            }

            @include media-breakpoint-down(md) {
                /*height: calc(85vh - 57px);*/
                min-height: 400px;
                height: 600px;
            }

            @include media-breakpoint-down(sm) {
                /*height: calc(85vh - 57px);*/
                min-height: 400px;
                height: 410px;
            }
            //When overlay is applied to the banner, text color will change to not get lost in the overlay / filter
            &.overlay {
                .content {
                    color: white;
                }
            }

            .banner-tablet {
                display: none;

                @include media-breakpoint-down(md) {
                    display: block;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: top center;
                }

                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }

            .banner-mobile {
                display: none;

                @include media-breakpoint-down(sm) {
                    display: block;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: top center;
                }
            }

            .banner-overlay {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            .content {
                position: absolute;
                padding: 15px;

                @include media-breakpoint-down(md) {
                    padding: 15px 5px;
                }

                &.bottom-right {
                    bottom: 15px;
                    right: 15px;

                    @include media-breakpoint-down(sm) {
                        right: 0px;
                    }

                    &.has-nav {
                        bottom: 125px;

                        @include media-breakpoint-down(md) {
                            bottom: 0px;
                        }
                    }
                }

                &.top-left {
                    top: 60px;
                    left: 15px;

                    @include media-breakpoint-down(sm) {
                        top: auto;
                        bottom: 15px;
                    }
                }


                .title {
                    margin: 0;
                    font-size: 1.35rem;

                    @include media-breakpoint-down(xs) {
                        font-size: 1.25rem;
                    }

                    @include media-breakpoint-up(md) {
                        font-size: 2rem;
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: 2.2rem;
                    }

                    display: inline-block;
                    clear: both;
                    color: $white;
                    font-family: $font-family-sans-serif;
                    font-weight: lighter;
                    background-color: $odu-slate-blue-dark;
                    padding: 0.25em;
                    padding-left: 0.75em;
                    padding-right: 0.75em;
                    margin-bottom: 5px;

                    &.header1 {
                    }

                    &.header2 {
                        margin-left: 1em;
                    }
                }

                .btn {
                    font-size: 1.15rem;

                    @include media-breakpoint-down(xs) {
                        font-size: 1rem;
                    }

                    @include media-breakpoint-up(md) {
                        font-size: 1.75rem;
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: 1.95rem;
                    }

                    margin-left: 0.25em;
                    margin-bottom: 5px;
                    white-space: normal;

                    &.btn-outline-primary {
                        border-width: 0;
                        color: #fff;
                        border-color: $odu-slate-blue-dark;
                        font-weight: 600;
                        padding: 0.25em;
                        padding-left: 0.75em;
                        padding-right: 0.75em;
                        text-transform: uppercase;

                        &:hover, &:active, &:focus {
                            background-color: $white;
                            border-color: $white;
                            color: $odu-slate-blue-dark;
                        }
                    }
                }

                &.slate-blue {
                    .title {
                        background-color: $odu-slate-blue;
                    }

                    .btn.btn-outline-primary {
                        background-color: $odu-slate-blue;
                        border-color: $odu-slate-blue;

                        &:hover, &:active, &:focus {
                            background-color: $white;
                            border-color: $white;
                            color: $odu-slate-blue;
                        }
                    }
                }

                &.aqua-blue {
                    .title {
                        background-color: $odu-aqua-blue-mid;
                    }

                    .btn.btn-outline-primary {
                        background-color: $odu-aqua-blue-mid;
                        border-color: $odu-aqua-blue-mid;

                        &:hover, &:active, &:focus {
                            background-color: $white;
                            border-color: $white;
                            color: $odu-aqua-blue-mid;
                        }
                    }
                }

                &.dark-green {
                    .title {
                        background-color: $odu-dark-green;
                    }

                    .btn.btn-outline-primary {
                        background-color: $odu-dark-green;
                        border-color: $odu-dark-green;

                        &:hover, &:active, &:focus {
                            background-color: $white;
                            border-color: $white;
                            color: $odu-dark-green;
                        }
                    }
                }

                &.green {
                    .title {
                        background-color: $odu-light-green;
                    }

                    .btn.btn-outline-primary {
                        background-color: $odu-light-green;
                        border-color: $odu-light-green;

                        &:hover, &:active, &:focus {
                            background-color: $white;
                            border-color: $white;
                            color: $odu-light-green;
                        }
                    }
                }

                &.gray {
                    .title {
                        background-color: $odu-darker-gray;
                    }

                    .btn.btn-outline-primary {
                        background-color: $odu-darker-gray;
                        border-color: $odu-darker-gray;

                        &:hover, &:active, &:focus {
                            background-color: $white;
                            border-color: $white;
                            color: $odu-darker-gray;
                        }
                    }
                }

                &.light-gray {
                    .title {
                        background-color: $odu-light-gray-blue;
                    }

                    .btn.btn-outline-primary {
                        background-color: $odu-light-gray-blue;
                        border-color: $odu-light-gray-blue;

                        &:hover, &:active, &:focus {
                            background-color: $white;
                            border-color: $white;
                            color: $odu-light-gray-blue;
                        }
                    }
                }

                &.bottom-alignment-mid {
                    @media (max-width: 1314px) {
                        top: auto;
                        bottom: 15px;
                        left: 0px;
                    }
                }
            }

            @media (max-width: 1314px) {
                &.contain {
                    //background-size: ;
                    height: auto !important;
                    min-height: calc(872 / 1600 * 100vw) !important;
                    background-size: cover;

                    .banner-mobile {
                        height: auto !important;
                        background-size: cover;

                        &.inherit {
                            min-height: calc(872 / 1600 * 100vw) !important;
                        }

                        &.override {
                            min-height: calc(640 / 640 * 100vw) !important;
                        }
                    }

                    .banner-tablet {
                        height: auto !important;
                        min-height: calc(872 / 1600 * 100vw) !important;
                        background-size: cover;
                    }
                }
            }

            .banner-image-full {
                position: relative;
                width: 100%;

                .banner-img {
                    position: relative;
                    display: block;
                    max-width: 100%;
                    height: auto;
                    margin-left: auto;
                    margin-right: auto;
                    width: 100%;
                }
            }

            &.no-nav {
                //background-size: ;
                height: auto !important;
                min-height: calc(872 / 1600 * 100vw) !important;
                background-size: cover;

                .banner-mobile {
                    height: auto !important;
                    background-size: cover;

                    &.inherit {
                        min-height: calc(872 / 1600 * 100vw) !important;
                    }

                    &.override {
                        min-height: calc(640 / 640 * 100vw) !important;
                    }
                }

                .banner-tablet {
                    height: auto !important;
                    min-height: calc(872 / 1600 * 100vw) !important;
                    background-size: cover;
                }
            }

            @media (max-width: 319px) {
                .banner-mobile {
                    position: relative;
                }

                .top-banner .content {
                    position: relative;
                    background: #020d21;
                }
            }

            &.full-image {
                min-height: unset !important;
                height: auto !important;
            }
        }

        .banner.subpage {
            /*height: 100vh;
            height: calc(70vh - 150px);*/
            height: 510px;
            min-height: 400px;

            @include media-breakpoint-down(md) {
                height: 400px;
            }


            @media (max-width: 1314px) {
                &.contain {
                    min-height: calc(460 / 1600 * 100vw) !important;

                    .banner-mobile {
                        &.inherit {
                            min-height: calc(460 / 1600 * 100vw) !important;
                        }

                        &.override {
                            min-height: calc(640 / 640 * 100vw) !important;
                        }
                    }

                    .banner-tablet {
                        min-height: calc(872 / 1600 * 100vw) !important;
                    }
                }
            }
        }

        .subpage-content {
            width: 100%;
            background-color: $odu-slate-blue;
            padding: 15px;
            color: $white;
            text-align: center;

            &.slate-blue {
                background-color: $odu-slate-blue;
            }

            &.dark-slate-blue {
                background-color: $odu-slate-blue-dark;
            }

            &.aqua-blue {
                background-color: $odu-aqua-blue-mid;
            }

            &.dark-green {
                background-color: $odu-dark-green;
            }

            &.green {
                background-color: $odu-light-green;
            }

            &.gray {
                background-color: $odu-darker-gray;
            }

            &.light-gray {
                background-color: $odu-light-gray-blue;
                color: $black;
            }

            .title {
                font-size: 23px;
                text-transform: uppercase;
                font-family: $headings-font-family;
                font-weight: bold;
                margin-bottom: 15px;
                display: inline-block;

                &.no-text {
                    margin-bottom: 0;
                }
            }

            .text {
                font-weight: normal;
                max-width: 800px;
                margin-left: auto;
                margin-right: auto;
            }
        }

        @include media-breakpoint-down(md) {
            margin-bottom: 0;
        }
    }
}

body.subpage {
    main {
        .banner-holder {
            .banner {
                &, &.no-nav, .banner-tablet, .banner-mobile {
                    max-height: 650px !important;
                    height: calc(100vh - 150px) !important;
                    min-height: 400px !important;
                }
            }

            margin-bottom: 3rem;

            @include media-breakpoint-down(md) {
                margin-bottom: 2rem;
            }

            @include media-breakpoint-down(sm) {
                .banner.featured, .banner.subpage {
                    &, &.no-nav, .banner-tablet, .banner-mobile {
                        max-height: 400px !important;
                        height: 400px !important;
                        min-height: 400px !important;
                    }
                }
            }

            .banner {
                // note: full-image displays the full, uncropped image, full-width.. similar to img-response.
                &.full-image {
                    &, &.no-nav {
                        max-height: unset !important;
                        height: auto !important;
                        min-height: unset !important;
                    }
                }
            }
        }
    }
}

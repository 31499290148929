﻿/// <reference path = "../marathon.scss" />
// File Name: _nav-primary.scss
// Description: Primary navigation styles
// ------------------------------------------------------------

nav {
    position: relative;

    &.nav-primary {
        @include media-breakpoint-down(md) {
            @include make-container();
            padding-left: 0;
            padding-right: 0;
        }

        border-bottom: 1PX solid $gray-400;

        nav {

            .navbar-brand {
                padding-top: .75rem;
                padding-left: .5rem;
                padding-bottom: 0;
            }

            .mobile-header-wrapper {
                @include media-breakpoint-down(md) {
                    position: relative;
                    @include make-row();
                    margin-left: 0;
                    margin-right: 0;
                    align-items: center;
                    justify-content: space-between;
                    -ms-flex-direction: row-reverse;
                    -webkit-flex-direction: row-reverse;
                    flex-direction: row-reverse;
                }
            }

            .navbar-toggler {
                font-size: 26px;
                height: 100%;
                border-radius: 0;
                outline: 0 !important;
                width: 3.5em;

                span {
                    font-size: 20px;
                }

                &.close-menu {
                    -moz-transition: 2s all;
                    -o-transition: 2s all;
                    -webkit-transition: 2s all;
                    transition: 2s all;
                }
            }

            .nav-wrapper {
                @include make-container();
                display: flex;
                justify-content: space-between;

                @include media-breakpoint-down(md) {
                    display: none;
                }

                #primary-collapsing-navbar {

                    .navbar-nav {
                        float: right;

                        > .nav-item {

                            > .nav-link {
                                color: blue;
                                padding: 2rem .5rem;
                            }

                            &.dropdown {
                                .dropdown-menu {
                                    ul {
                                        padding: 0;

                                        a {
                                            padding-left: 5px;
                                            padding-right: .5rem;
                                        }
                                    }
                                }

                                &.megamenu {
                                    &:hover {
                                        > .dropdown-menu {
                                            visibility: visible;
                                        }
                                    }

                                    > .dropdown-menu {
                                        visibility: hidden;
                                        display: block;
                                        right: 0;
                                        left: auto;
                                        background-color: transparent;
                                        padding: 0;
                                        min-width: 800px;

                                        &.center {
                                            left: 0;
                                            right: 0;
                                            margin: auto;
                                        }

                                        > .megamenu-container {
                                            @include make-container();
                                            @include make-container-max-widths();

                                            .megamenu-row {
                                                @include make-row();
                                                background-color: white;
                                            }

                                            .content-menu-column {
                                                .content-menu-image {
                                                    img {
                                                        @extend .img-fluid;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                > .nav-link {

                                    &:after {
                                        @include fa-icon();
                                        color: $black;
                                        font-size: 12px;
                                        content: "\f078";
                                        padding-left: .5rem;
                                    }
                                }

                                .dropdown-menu {
                                    margin-top: 0;

                                    ul {
                                        list-style: none outside none;
                                    }

                                    .nav-item {
                                        float: none;

                                        + .nav-item {
                                            margin-left: 0;
                                        }
                                    }

                                    a.dropdown {
                                        &:after {
                                            @include fa-icon();
                                            color: $black;
                                            font-size: 12px;
                                            content: "\f054";
                                            padding-left: .5rem;
                                            float: right;
                                            position: relative;
                                            top: 7px;
                                        }
                                    }
                                }

                                &:hover {

                                    > .dropdown-menu {
                                        display: block;

                                        .dropdown {
                                            &:hover {
                                                > .dropdown-menu {
                                                    display: block;
                                                    left: 100%;
                                                    top: 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &:last-child {
                                .dropdown-menu {
                                    left: auto;
                                    right: 0;
                                }

                                > .nav-link {
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
